.navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    max-width: 100vw;
    max-height: 100px;
}

.navbar-items-container{
    display:flex;
    flex-direction: row;
    align-items: center;
}

.navbar-item{
    font-size: 2rem;
    margin: 0 0.5em;
    color: #8f7b56;
    text-decoration: none;
}

.navbar-dot{
    height: 0.4em;
    width: 0.4em;
    border-radius: 100%;
    background-color: #8f7b56;
    margin-top: 0.5em;
}

@media only screen and (max-width: 1300px){
    .navbar-item{
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 900px){
    .navbar-items-container{
        display: none;
    }
}

