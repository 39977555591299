.full-section-uno{
    height: 100vh;
    max-width: 100vw;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    padding-left: 5vw;
    padding-top: 10vh;
}

.col-1-uno{
    display: flex;
    flex-direction: column;
    width: 55vw;
    align-items: flex-end;
}

.col-1-uno a{
    background-color: #FEE445;
    color: #000000;
}

.logo-uno{
    max-width: 55vw;
}

.col-1-uno p{
    margin-top: 8rem;
}

.col-2-uno{
    margin-left: 5vw;
    display: flex;
    align-items: flex-end;
    width: 35vw;
    overflow: hidden;
}

.col-2-uno img{
    max-width: 100vw;
    height: 75vh;
}

@media only screen and (max-height:55vw){
    .full-section-uno{
        max-height: none;
        height: auto;
    }
}

@media only screen and (max-width:900px){
    .full-section-uno{
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
        height: auto;
        padding: 5vh 5vw;
    }
    .col-1-uno{
        width: 90vw;
    }
    .col-1-uno a{
        align-self: flex-start;
        position: relative;
        top: 10rem;
        width: 34vw;
    }
    .logo-uno{
        max-width: none;
        width: 90vw;
    }
    .col-2-uno{
        align-self: flex-end;
        width: 50vw;
        height: 350px;
        position: relative;
        right: -5vw;
        top: -5rem;
        align-items: flex-end;
    }
    .img-uno{
        max-width: none;
        max-height: 100%;
    }
}

@media only screen and (max-width:700px){
    .col-2-uno{
        top: -3rem
    }
}

@media only screen and (max-width:600px){
    .col-2-uno{
        top: -2rem
    }
}

@media only screen and (max-width:450px){
    .col-1-uno p{
        margin-top: 4rem;
    }
    .col-1-uno a{
        top:5rem;
        left: 2rem;
    }
    .col-2-uno{
        top: -2rem;
        right: -3rem;
        height: 200px;
    }
}

@media only screen and (max-width:400px){
    .col-2-uno{
        top: -1rem
    }
}




