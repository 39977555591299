@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');

#root{
  font-family: 'Barlow Condensed', sans-serif;
  overflow: hidden;
}

.full-section{
  height: 100vh;
  max-width: 100vw;
  display: flex;
  padding: 5vh 5vw 5vh 5vw;
}

.text-section{
  font-family: 'Barlow Condensed', sans-serif;
  color: #2e2c2c;
  text-align: justify;
  font-size: 2.2rem;
}

.button{
  font-family: 'Barlow Condensed', sans-serif;
  background-color: #74d654;
  text-decoration: none;
  color: white;
  border-radius: 0.3em;
  padding: 0.25em 0.25em;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 2.2rem;
  text-align: center;
  z-index: 10;
  max-width: 20%;
}

@media only screen and (max-width: 1400px){
  .button{
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 1100px) {
  .button{
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 960px) {
  .button{
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 900px) {
  .button{
    font-size: 2.2rem;
    max-width: 300px;
  }
}

@media only screen and (max-width:600px){
  .button{
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 385px){
  .button{
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 340px){
  .button{
    font-size: 1rem;
  }
}