@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  font-family: 'Barlow Condensed', sans-serif;
  overflow: hidden;
}

.full-section{
  height: 100vh;
  max-width: 100vw;
  display: flex;
  padding: 5vh 5vw 5vh 5vw;
}

.text-section{
  font-family: 'Barlow Condensed', sans-serif;
  color: #2e2c2c;
  text-align: justify;
  font-size: 2.2rem;
}

.button{
  font-family: 'Barlow Condensed', sans-serif;
  background-color: #74d654;
  text-decoration: none;
  color: white;
  border-radius: 0.3em;
  padding: 0.25em 0.25em;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 2.2rem;
  text-align: center;
  z-index: 10;
  max-width: 20%;
}

@media only screen and (max-width: 1400px){
  .button{
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 1100px) {
  .button{
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 960px) {
  .button{
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 900px) {
  .button{
    font-size: 2.2rem;
    max-width: 300px;
  }
}

@media only screen and (max-width:600px){
  .button{
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 385px){
  .button{
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 340px){
  .button{
    font-size: 1rem;
  }
}
.navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    max-width: 100vw;
    max-height: 100px;
}

.navbar-items-container{
    display:flex;
    flex-direction: row;
    align-items: center;
}

.navbar-item{
    font-size: 2rem;
    margin: 0 0.5em;
    color: #8f7b56;
    text-decoration: none;
}

.navbar-dot{
    height: 0.4em;
    width: 0.4em;
    border-radius: 100%;
    background-color: #8f7b56;
    margin-top: 0.5em;
}

@media only screen and (max-width: 1300px){
    .navbar-item{
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 900px){
    .navbar-items-container{
        display: none;
    }
}


.full-section{
    max-height: 90vh;
    background-image: url(/static/media/bg-home.14033e19.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: row;
}

.logo-adtg{
    width: 20vw;
    position: relative;
    top:-9vh;
}

.logo-adtg img{
    max-width: 20vw;
}

.adtg-content{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 55vw;
    margin-left: 10vw;
    margin-top: 5vh;
}

.text-container{
    display:flex;
    flex-direction: column;
}

.text-container a{
    margin-top: 6vh;
    max-width: 250px;
}


.video-frame-container{
    align-self: flex-end;
    position: relative;
    bottom:4em;
    right: 2em;
    width: 30vw;
    height: 40vh;
}

.video-frame{
    width: 100%;
    height: 80%;
}

.video-frame-footer{
    background-color: white;
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1em;
}

.video-frame-footer p{
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
}

.youtube-button{
    display: flex;
    flex-direction: row;
    background-color: #c4302b;
    align-items: center;
    margin-right: 0.8rem;
    padding: 0 1em;
    border-radius: 0.7rem;
    text-decoration: none;

}

.youtube-button img{
    width: 2.5rem;
    height: 1.6rem;
    margin-right: 0.3rem;
}

.youtube-button{
    padding: 0 0.5em;
}

.youtube-button p{
    color: white;
    margin: 0;
}

@media only screen and (max-height: 880px){
    .full-section{
        max-height: 150vh;
    }
}

@media only screen and (max-height: 700px){
    .full-section{
        height: auto;
    }
}

@media only screen and (max-width: 1650px){
    .video-frame-footer p{
        font-size: 0.8rem;
    }
    .youtube-button img{
        width: 2.2rem;
        height: 1.4rem;
    }
    .text-section{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 1400px){
    .adtg-content{
        margin-top: 3vh;
    }
    .text-section{
        font-size: 1.8rem;
    }
    .text-container a{
        width: 200px;
    }
    .video-frame-container{
        height: 35vh;
    }
    .video-frame-footer p{
        font-size: 0.6rem;
        font-weight: 600;
    }
    .youtube-button{
        border-radius: 0.5rem;
    }
    .youtube-button img{
        width: 1.8rem;
        height: 1.2rem;
    }
}

@media only screen and (max-width: 1100px) {
    .adtg-content{
        margin-top: 0;
    }
    .text-section{
        font-size: 1.6rem;
    }
    .text-container a{
        width: 180px;
    }
    .video-frame-container{
        height: 30vh;
    }
    .video-frame-footer{
        padding: 0.5em;
    }
    .video-frame-footer p{
        /* font-size: 0.5rem; */
    }
    .youtube-button{
        margin-right: 0.5rem;
        padding: 0 0.3rem;
    }
    .youtube-button img{
        width: 1.4rem;
        height: 1rem;
    }
}

@media only screen and (max-width:1000px){
    .video-frame-container{
        position: static;
        align-self: center;
        margin-top: 7vh;
        width: 45vw;
        height: 30vh;
    }
    .video-frame-footer p{
        font-size: 0.8rem;
    }
    .text-container{
        max-width: 100%;
        align-items: center;
    }
}

@media only screen and (max-width: 900px) {
    .full-section{
        flex-direction: column;
        align-items: center;
        max-height: none;
        height: auto;
        margin-top: 5vh;
    }
    .logo-adtg{
        width: 50vw;
    }
    .logo-adtg img{
        max-width: 50vw;
        top: -10vh;
    }
    .adtg-content{
        height: auto;
        max-width:96vw;
        margin: 0;
    }
    .text-container{
        max-width: 100%;
        align-items: center;
    }
    .text-section{
        font-size: 2.2rem;
    }
    .text-container a{
        width: 250px;
    }
    .video-frame-container{
        width: 90vw;
        height: 45vh;
        margin-top: 15vh;
    }
    .video-frame-footer p{
        font-size: 1rem;
    }
}

@media only screen and (max-width:600px){
    .text-section{
        font-size: 1.8rem;
    }
    .video-frame-container{
        height: 35vh;
        margin-top: 10vh;
    }
    .text-container a{
        width: 200px;
    }

}

@media only screen and (max-width: 450px) {
    .logo-adtg{
        width: 65vw;
    }
    .logo-adtg img{
        max-width: 65vw;
    }
    .text-container a{
        width: 200px;
    }
    .text-section{
        width: 85vw;
        font-size: 1.5em;
    }
    .video-frame-footer p{
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 385px){
    .text-section{
        font-size: 1.3rem;
    }
    .text-container a{
        width: 150px;
    }
    .video-frame-footer p{
        font-size: 0.7em;
    }
    .youtube-button{
        border-radius: 0.3rem;
        padding: 0 0.3rem;
    }
    .youtube-button img{
        max-width: 20px;
        max-height: 20px;
    }
}

@media only screen and (max-width: 340px){
    .text-section{
        font-size: 1.1rem;
    }
    .video-frame-footer p{
        font-size: 0.55rem;
    }
}
.full-section-sinos{
    max-width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    padding-bottom: 5vh;
}

.red-section{
    height: 10vh;
    max-width: 100vw;
    background-color: #EB1C2E;
}

.sinos-container{
    max-height: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    padding: 0 0 0 5vw;
}

.col-1{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 60vw;
}

.col-1 a{    
    align-self: end;
}

.logo-sinos{
    margin-top: 4rem;
    max-width: 35vw;
    max-height: 35vh;
    margin-bottom: 8rem;
}

.col-2{
    max-width: 35vw;
    max-height: 85vh;
}

.img-flautista{
    height: 100%;
    max-width: 100%;    
    position: relative;
    top: -2em;
    left: -6em;
}
@media only screen and (max-height:50vw){
    .full-section-sinos{
        max-height: none;
        height: auto;
    }
}

@media only screen and (max-width: 900px){
    .full-section-sinos{
        max-height: none;
        height: auto;
        padding: 0;
    }
    .col-2{
        height: 30vh;
    }
    .img-flautista{
        top: -4em;
        left: auto;
    }
    .logo-sinos{
        max-width: 55vw;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
    .col-1 p{
        width: 89vw;
        margin: auto;
    }
    .col-1 a{
        margin: 5vh 0;
    }
}

@media only screen and (max-width:450px){
    .col-2{
        height: 25vh;
    }
    .img-flautista{
        top:-2em;
        left: -1em;
    }
    .logo-sinos{
        margin-top: 1rem;
    }
}
.full-section-bossa{
    height: 100vh;
    max-width: 100vw;
    background-image: url(/static/media/bg-bossa.0a83109d.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    padding: 5vh 5vw 5vh 5vw;
}

.cangaceiro{
    max-height: 90vh;
    max-width: 20vw;
}

.content-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 68vw;
    margin-left: 2vw;
}

.content-container a{
    background-color: #ffffff;
    color: #000000;
    align-self: flex-start;
}

.logo-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 6em;
}

.logo-bossa{
    margin-left: 8vw;
    max-width: 40vw;
}
.boy{
    max-width: 20vw;
}

.text-section p{
    text-align: justify;
    text-align-last: right;
    margin-bottom: 2em;
}

@media only screen and (max-height:55vw){
    .full-section-bossa{
        max-height: none;
        height: auto;
    }
}

@media only screen and (max-width:1700px){
    .cangaceiro{
        max-height: 75vh;
    }
}

@media only screen and (max-width:1450px){
    .cangaceiro{
        max-height: 65vh;
        margin-top: 10vh;
    }
}

@media only screen and (max-width:1150px){
    .cangaceiro{
        max-height: 60vh;
    }
}

@media only screen and (max-width: 900px){
    .full-section-bossa{
        display: grid;
        grid-gap: 0px;
        height: auto;
    }
    .logo-container{
        transform: scaleX(-1);
        display: flex;
        justify-content: center;
        margin-bottom: 8rem;
        max-height: 300px;
    }
    .boy{
        /* max-height: 300px; */
        margin-left: 25px;
        
    }
    .logo-bossa{
        margin-left: 0;
        transform: scaleX(-1);
        max-width: 50vw;
    }
    .cangaceiro-container{
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;    
        max-width: 20vw;
        max-height: 300px;
    }
    .cangaceiro{
        max-height: 300px;
        margin-top: 0;  
        position: relative;
        right: -3vw;  
    }
    .content-container{
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 3;
    }
    .content-container p{
        width: 90vw;
        justify-self: center;
        margin: auto;
        text-align-last: left;
    }
    .content-container a{
        align-self: flex-end;
        margin: 4rem 0 2rem 0;
    }
}

@media only screen and (max-width:800px){
    .logo-container{
        max-height: 250px;
    }
    .cangaceiro-container{
        max-height: 250px;
    }
    .cangaceiro{
        right: -2vw;
        max-height: 250px;
    }
}

@media only screen and (max-width:730px){
    .cangaceiro{
        right: -1vw;
    }
}

@media only screen and (max-width:620px){
    .logo-container{
        max-height: 200px;
    }
    .cangaceiro-container{
        max-height: 200px;
    }
    .cangaceiro{
        max-height: 200px;
    }
}

@media only screen and (max-width:500px){
    .logo-container{
        max-height: 170px;
        margin-bottom: 6rem;
    }
    .cangaceiro-container{
        max-height: 170px;
    }
    .cangaceiro{
        right: 0;
        max-height: 170px;
    }
    .content-container p{
        position: relative;
        left: -5vw;
        max-width: 80vw;
        
    }
    .content-container a{
        position: relative;
        left: -5vw;
    }
}
@media only screen and (max-width:450px){
    .content-container p{
        left: -3vw;
    }
    .content-container a{
        left: -3vw;
    }
}
@media only screen and (max-width:410px){
    .logo-container{
        max-height: 150px;
        margin-bottom: 3rem;
    }
    .cangaceiro-container{
        max-height: 150px;
    }
    .cangaceiro{
        max-height: 150px;
    }
}
@media only screen and (max-width:365px){
    .logo-container{
        max-height: 120px;
    }
    .cangaceiro-container{
        max-height: 120px;
    }
    .cangaceiro{
        max-height: 120px;
    }
}
.full-section-uno{
    height: 100vh;
    max-width: 100vw;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    padding-left: 5vw;
    padding-top: 10vh;
}

.col-1-uno{
    display: flex;
    flex-direction: column;
    width: 55vw;
    align-items: flex-end;
}

.col-1-uno a{
    background-color: #FEE445;
    color: #000000;
}

.logo-uno{
    max-width: 55vw;
}

.col-1-uno p{
    margin-top: 8rem;
}

.col-2-uno{
    margin-left: 5vw;
    display: flex;
    align-items: flex-end;
    width: 35vw;
    overflow: hidden;
}

.col-2-uno img{
    max-width: 100vw;
    height: 75vh;
}

@media only screen and (max-height:55vw){
    .full-section-uno{
        max-height: none;
        height: auto;
    }
}

@media only screen and (max-width:900px){
    .full-section-uno{
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
        height: auto;
        padding: 5vh 5vw;
    }
    .col-1-uno{
        width: 90vw;
    }
    .col-1-uno a{
        align-self: flex-start;
        position: relative;
        top: 10rem;
        width: 34vw;
    }
    .logo-uno{
        max-width: none;
        width: 90vw;
    }
    .col-2-uno{
        align-self: flex-end;
        width: 50vw;
        height: 350px;
        position: relative;
        right: -5vw;
        top: -5rem;
        align-items: flex-end;
    }
    .img-uno{
        max-width: none;
        max-height: 100%;
    }
}

@media only screen and (max-width:700px){
    .col-2-uno{
        top: -3rem
    }
}

@media only screen and (max-width:600px){
    .col-2-uno{
        top: -2rem
    }
}

@media only screen and (max-width:450px){
    .col-1-uno p{
        margin-top: 4rem;
    }
    .col-1-uno a{
        top:5rem;
        left: 2rem;
    }
    .col-2-uno{
        top: -2rem;
        right: -3rem;
        height: 200px;
    }
}

@media only screen and (max-width:400px){
    .col-2-uno{
        top: -1rem
    }
}





